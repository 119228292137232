import React from 'react';

export default class Education extends React.Component {
  
  render() {
    return (
      <section id={this.props.id}>
        <h4>Education</h4>
        <h3>M.S., Software Engineering</h3>
        <h5>George Mason University, 2011</h5>
        <h3>B.S., Computer Science</h3>
        <h5>American University of Beirut, 2003</h5>
      </section>
    );
  }

}
