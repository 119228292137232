import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <App />,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();


// Event handler for the "UpSiDeDoWn" Easter Egg
var addEvent = document.addEventListener ?
  function (target, type, action) {
    if (target) {
      target.addEventListener(type, action, false);
    }
  } :
  function (target, type, action) {
    if (target) {
      target.attachEvent('on' + type, action, false);
    }
  }

let keyMap = {};
let triggered = function (e) {
  e = e || window.event;
  var key = e.which || e.keyCode;
  keyMap[key] = (e.type === 'keydown');

  if (keyMap[85] && keyMap[68]) {
    document.body.classList.add('flip');
  } else {
    document.body.classList.remove('flip');
  }
}

addEvent(document, 'keydown', triggered);
addEvent(document, 'keyup', triggered);
