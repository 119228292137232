import React from 'react';

export default class Experience extends React.Component {
  
  render() {
    return (
      <section id="experience" className={this.props.cssClass}>
        <h4>Work Experience</h4>
        
        <h3>Principal Software Engineer</h3>
        <h5>EDF Renewables&nbsp;&nbsp;|&nbsp;&nbsp;2020–2021</h5>
        <p>
          Led the engineering team on the Global Solar Optimization
          Platform which was responsible for onboarding new solar plants
          as well as data ingestion, analytics, and visualization of 25+
          sites across the globe. The synthesized data helped stakeholders
          identify opportunities to improve energy production, detect and
          mitigate issues, and monitor overall site health and performance.
        </p>
        <ul>
          <li>
            Streamlined the onboarding of new solar sites by identifying
            constraints and automating large portions of the process.
          </li>
          <li>
            Increased the total number of sites by almost 3x and increased
            the daily volume of data to ~30 million data points per day.
          </li>
          <li>
            Collaborated closely with global partner engineering teams,
            building a technical roadmap to transition from the original MVP
            to a future-facing, highly-resilient, and scalable system, with
            the long-term goal of being able to process data in near real-time.
          </li>
          <li>
            Transitioned from a tightly-coupled polling model to an event-driven
            model which reduced infrastructure needs by 45% while also increasing
            the stability and maintainability of the system.
          </li>
          <li>
            Integrated with external data sources (eg. GIS, SAP) to assemble a
            comprehensive overview of the sites’ energy and financial KPIs.
          </li>
          <li>
            Hired, onboarded, and trained additional data engineers for the Solar
            and Storage teams for future growth and feature delivery.
          </li>
        </ul>
        <br />

        <h3>Engineering Manager</h3>
        <h5>Nike&nbsp;&nbsp;|&nbsp;&nbsp;2016–2019</h5>
        <p>
          Managed the Asset Delivery team within the Content Ecosystem, 
          leading an effort to gain a deep understanding of the various
          asset repositories across Nike's digital landscape and move to
          a simplified, centralized, and more cost-effective system.
        </p>
        <p>
          Prior to that, I managed the Content team responsible for the
          CMS that is used by the global authoring team to publish content
          on Nike.com and deliver it to consumers worldwide.
        </p>
        <ul>
          <li>
            Delivered high-res product images and video to the global
            eCommerce platforms - Nike.com and the Nike, SNKRS, NTC,
            and NRC apps - spanning 58 countries and 85 languages.
          </li>
          <li>
            Responsible for over nine billion product image and
            video requests - over 350TB of media - globally, per
            day, across the five platforms.
          </li>
          <li>
            Worked closely with partner teams to integrate with
            upstream, downstream, and external third-party services,
            to ingest and organize media, automate content creation,
            and deliver best-in-class consumer experiences.
          </li>
          <li>
            Managed vendor partner relations and guided contract
            negotiations based on internal metrics and future
            growth projections.
          </li>
        </ul>
        <br />

        <h3>Tech Lead</h3>
        <h5>Nike&nbsp;&nbsp;|&nbsp;&nbsp;2014–2016</h5>
        <p>
          Led the engineering scrum teams, providing technical guidance,
          coaching, and architectural direction. Served as the primary 
          technical contact to the business stakeholders, authoring community,
          as well as external groups.
        </p>
        <ul>
          <li>
            Led the team through two major milestones: (1) a core application
            upgrade to increase the security and stability of the platform, and
            (2) a re-architecture to support the expansion into 30+ additional
            countries and languages.
          </li>
          <li>
            Completely rewrote the SNKRS project, giving authors a means to
            author rich content, enabling the launch of the first version
            of the SNKRS mobile app.
          </li>
          <li>
            Built a suite of administrative tools to automate content migration, 
            reporting, archiving, and data manipulation, reducing operational 
            overhead by 50%.
          </li>
          <li>
            Helped build the roadmap, providing the business team with a 
            technical assessment of requirements, feasibility, and associated
            levels of effort.
          </li>
          <li>
            Hosted weekly engineering design sessions to discuss and assess 
            major upcoming feature work.
          </li>
          <li>
            Guided the team through multiple releases by managing the process
            from start to finish, then attending and overseeing the releases
            in-person to ensure a smooth process.
          </li>
          <li>  
            Developed an automated test suite that ensured that the foundations
            of all testing environments were identical and streamlined the
            process to efficiently load content into test environments.
          </li>
        </ul>
        <br />

        <h3>Senior Application Engineer</h3>
        <h5>Nike&nbsp;&nbsp;|&nbsp;&nbsp;2013–2014</h5>
        <p>
          Joined the core content management team under Nike Digital Tech to 
          help develop new features and contribute to the improvement and
          stability of the underlying COTS CMS application.
        </p>
        <ul>
          <li>
            Worked in one of four scrum teams to build and maintain a growing
            set of reusable components used by the authoring teams to create
            rich story-telling content central to the consumer journey on
            Nike.com.
          </li>
          <li>
            Successfully led an effort to address security issues in the
            application by regression testing and subsequently applying
            available hotfixes.
          </li>
          <li>
            Developed a series of scripts to update and migrate data within the
            underlying data repository.
          </li>
          <li>
            Set up, configured, and troubleshooted test environments for
            development and quality assurance prior to production deployments.
          </li>
        </ul>
        <br />

        <h3>Senior Software Engineer</h3>
        <h5>National Gallery of Art&nbsp;&nbsp;|&nbsp;&nbsp;2012–2013</h5>
        <p>
          Led an effort to research, design, and build the gallery’s
          new website using a newly-acquired COTS content management
          platform, working closely with the executive and design
          teams to refine requirements and adjust the delivery
          timelines. 
        </p>
        <ul>
          <li>
            Developed RESTful backend services used to generate
            dynamic pages using the internal artwork databases,
            as well as static and authored pages using reusable
            templates and components.
          </li>
          <li>
            Built a wide assortment of custom frontend widgets, providing
            elegant methods of displaying content consistent with the
            gallery’s brand.
          </li>
          <li>
            Managed the transfer of the gallery's audio and video
            assets by leveraging third-party partner APIs to migrate
            content between hosting providers and created custom
            widgets for media playback.
          </li>
          <li>
            Wrote multiple scripts to programmatically create,
            mass-update, and migrate content directly into the content
            management system's underlying persistent data store.
          </li>
          <li>
            Mentored new engineers around best practices and collaborated
            closely with them to deliver on an ambitious schedule.
          </li>
        </ul>
        <br />

        <h3>Owner // Principle Engineer</h3>
        <h5>MobileAutonomy&nbsp;&nbsp;|&nbsp;&nbsp;2010–2012</h5>
        <p>
          Co-created a startup with the goal of reimagining and
          overhauling the standard ordering paradigm across the food
          services industry. Also partnered with a small advertising
          company, serving as their technical department, and built a
          custom, web-based CMS solution to distribute and manage
          media for their innovative digital advertising display units.
        </p>
        <ul>
          <li>
            Built a prototype iOS app that allowed customers to find
            locations, place orders, make payments, set spending limits,
            and receive notifications through their smartphone. 
          </li>
          <li>
            Architected and built a secure Java-based content management
            system from scratch for media storage, distribution, scheduling,
            and report generation.
          </li>
          <li>
            Aided in the design of a proprietary networking infrastructure
            for faster data transfer, lower mobile data usage, and increased
            security.
          </li>
          <li>
            Set up, installed, and configured over 350 units across the globe
            on our centralized, high speed, and secure proprietary network.
          </li>
          <li>
            Other projects included building a PHP-based college fantasy
            football website with an integrated PayPal payment system and
            a real-time draft system.
          </li>
          <li>
            Built the company's website using responsive web technologies
            for a seamless browser and mobile user experience.
          </li>
        </ul>

        {/* <h3>Software Engineer</h3>
        <h5>Evolvent&nbsp;&nbsp;|&nbsp;&nbsp;2006–2010</h5>
        <p>
          Joined the software team of a small government contracting company
          that working primarily with the U.S. military in healthcare 
          research and development, business process management, and content
          management.
        </p>
        <ul>
          <li>
            Investigated the use of the virtual world Second Life for use with
            PTSD mental health therapy and developed a server-side application
            to control a Second Life avatar programmatically using C# and
            Second Life's open-source API.
          </li>
          <li>
            Designed, customized, and modified a Java-based Business Process
            Management application for decision support, as well as a
            custom-built Enterprise Knowledge Management System for content
            processing for the U.S. Air Force Medical leadership.
          </li>
        </ul>
        <br />

        <h3>Junior Programmer</h3>
        <h5>Mideast Data Systems Lebanon&nbsp;&nbsp;|&nbsp;&nbsp;2004–2005</h5>
        <p>
          Began at a mid-sized software company specializing in ePayments and
          eCommerce solutions for the banking industry, including clients such
          as Riyad Bank, HBTF, Bank Audi, BBAC, and Bank of Cairo.
        </p>
        <ul>
          <li>
            Built custom components for a Java-based enterprise teller, sales,
            and services banking applications for HBTF's new branch automation
            system.
          </li>
          <li>
            Generated a series of automated scripts to generate reports on the
            daily transactions of bank ATMs and POS terminals.
          </li>
          <li>  
            Built a series of individual applications used to automatically
            translate and migrate data between old and new backend systems.
          </li>
        </ul> */}
      </section>
    );
  }

}
