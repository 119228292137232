import React from 'react';
import photo from './photo.jpg';

export default class Intro extends React.Component {
  
  render() {
    return (
      <section id="intro">
        <img src={photo} alt="Marc Saliba" id="photo" />
        <div>
          <h1>Marc Saliba</h1>
          <h2>Principal Software Engineer</h2>
        </div>
      </section>
    );
  }

}
