import React from 'react';

export default class About extends React.Component {
  
  constructor(props) {
    super(props);
    this.numberOfYears = (new Date()).getFullYear() - 2003;
  }

  render() {
    return (
      <section id="about">
        <h4>About Me</h4>
        <p>
          Empathetic, people-first, and data-driven engineer,
          focused on building, inspiring, and working closely
          with high-performing teams. Strong technical leadership
          skills built on a {this.numberOfYears}-year foundation across multiple
          domains and geographies.
        </p>
        <p>
          Currently exploring new opportunities for a leadership
          position where I can help foster an exemplary team and
          team culture rooted in trust, collaboration, passion,
          transparency, and learning.
        </p>
        <p>
          I’m looking to leverage my breadth of experience as
          an individual contributor and as a manager, to set
          the tone, lead by example, ask the right questions,
          and, above all, be in service to others.
        </p>
      </section>      
    );
  }

}

