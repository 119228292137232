import React from 'react';

export default class Certification extends React.Component {
  
  render() {
    return (
      <section id={this.props.id} className={this.props.cssClass}>
        <h4>Certification</h4>
        <h3>AWS Certified Developer &amp;<br />Solutions Architect Associate</h3>
        <h5>February 2022</h5>
        <h3>Sun Certified Java Programmer</h3>
        <h5>December 2007</h5>
      </section>
    );
  }

}
