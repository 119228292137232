import React from 'react';
import Intro from './_Intro.js';
import About from './_About.js';
import Education from './_Education.js';
import Certification from './_Certification.js';
import Contact from './_Contact.js';
import Experience from './_Experience.js';
import './App.css';

export default class App extends React.Component {

  render() {
    return (
      <>
        {/* <header>
          <div id="header-left">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20">
              <path d="M3 17v2h6v-2H3zM3 5v2h10V5H3zm10 16v-2h8v-2h-8v-2h-2v6h2zM7 9v2H3v2h4v2h2V9H7zm14 4v-2H11v2h10zm-6-4h2V7h4V5h-4V3h-2v6z"></path>
            </svg>
          </div>
          <div id="header-right">
            <button>
              Summary
            </button>
            &nbsp;&nbsp;|&nbsp;&nbsp;
            <button>
              Extended
            </button>
          </div>
        </header> */}
        <main>
            <div id="left">
              <Intro />
              <About />
              <Education id="education" />
              <Certification id="certification" />
              <Contact id="contact" />
            </div>
            <div id="right">
              <Experience />
              <Education id="education-bottom" />
              <Certification id="certification-bottom" />
              <Contact id="contact-bottom" />  
            </div>  
        </main>
        <footer>
          V3 // Uses AWS CodePipeline and was fully-templatized using CloudFormation.<br />
          V2 // Incorporated ReactJS and AWS.<br />
          V1 // Built using plain ol' HTML, CSS, and a little Javascript.
        </footer>
      </>
    );
  }
}
